import React, { useEffect, useState } from 'react';
import Header from './Common/Header';
import SideBar from './Common/SideBar';
import Footer from './Common/Footer';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import { Col, Row, Table } from 'react-bootstrap';
import profileImage from '../assets/images/5580909.png';
import axios from 'axios';

function MyProfile() {
    const [profileDetails, setProfileDetails] = useState(null);
    const userId = localStorage.getItem('userId');

    const fetchProfile = () => {
        axios.get(`https://alpastrology.online/alpapi/User_api/profile?user_id=${userId}`, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-api-key': 'alp@123'
            }
        }).then((response) => {
            if (response.data.status === 'Success') {
                setProfileDetails(response.data.data[0]);
            }
        }).catch((error) => {
            console.error("Error fetching profile:", error);
        });
    };

    useEffect(() => {
        fetchProfile();
    },[fetchProfile]);

    return (
        <>
            <Header />
            <SideBar />
            <div className="app-wrapper">
                <div className="app-content pt-3 p-md-3 p-lg-4">
                    <div className="container-xl">
                        <Row>
                            <Col lg={6} className='mx-auto'>
                                <Card>
                                    <Card.Header className='text-center fw-bold fs-3'>Profile</Card.Header>
                                    <div className='d-flex justify-content-center'>
                                        <Card.Img
                                            variant="top"
                                            src={profileImage}
                                            style={{ width: '150px', height: '150px', borderRadius: '50%' }}
                                        />
                                    </div>
                                    {profileDetails && (
                                        <ListGroup variant="flush">
                                            <Table>
                                                <tbody>
                                                    <tr>
                                                        <th>Name</th>
                                                        <td>:</td>
                                                        <td>{profileDetails.user_name}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Email</th>
                                                        <td>:</td>
                                                        <td>{profileDetails.user_email}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Mobile</th>
                                                        <td>:</td>
                                                        <td>{profileDetails.user_mob}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Date of Birth</th>
                                                        <td>:</td>
                                                        <td>{profileDetails.user_dob}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Time of Birth</th>
                                                        <td>:</td>
                                                        <td>{profileDetails.user_tob}</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Place of Birth</th>
                                                        <td>:</td>
                                                        <td>{profileDetails.user_pob}</td>
                                                    </tr>
                                                </tbody>
                                            </Table>
                                        </ListGroup>
                                    )}
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default MyProfile;
