import React, { useState, useEffect } from 'react';
import Header from './Common/Header'
import SideBar from './Common/SideBar'
import Footer from './Common/Footer'
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';


function MakeAppt() {
    const [selectedAstrologers, setSelectedAstrologers] = useState([]);
    const [availableAstrologers, setAvailableAstrologers] = useState([]);
    const apt_email = localStorage.getItem('userEmail');
    const [formData, setFormData] = useState({
        apt_date: '',
        apt_time: '',
        apt_user_name: '',
        apt_user_mobile: '',
        apt_user_email: apt_email,
        apt_user_address: '',
        apt_user_dob: '',
        apt_user_tob: '',
        apt_user_country: '',
        apt_user_state: '',
        apt_user_dist: '',
        appt_user_village: '',
        apt_user_pob: '',
        apt_mode: '',
        apt_services: [],
    });
    const [isSubmitting, setIsSubmitting] = useState(false); // Add loading state
    const navigate = useNavigate();



    const handleDateChange = (event) => {
        const date = event.target.value;

        fetch(`https://alpastrology.online/alpapi/User_api/get_available_astrologer?date=${date}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'x-api-key': 'alp@123',
            },
        })
            .then(response => response.json())
            .then(responseData => {
                console.log('Fetched available astrologers:', responseData);
                if (responseData.status === "Success" && Array.isArray(responseData.data)) {
                    setAvailableAstrologers(responseData.data);
                } else {
                    console.error('Expected an array but got:', responseData);
                    setAvailableAstrologers([]);
                }
            })
            .catch(error => {
                console.error('Error fetching available astrologers:', error);
                setAvailableAstrologers([]);
            });
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleCheckboxChange = (event) => {
        const { name, value, checked } = event.target;
        if (name === 'apt_services[]') {
            if (checked) {
                setFormData({ ...formData, apt_services: [...formData.apt_services, value] });
            } else {
                setFormData({ ...formData, apt_services: formData.apt_services.filter(service => service !== value) });
            }
        }
    };

    const handleAstrologerSelection = (userId) => {
        setSelectedAstrologers(prevSelected => {
            if (prevSelected.includes(userId)) {
                return prevSelected.filter(id => id !== userId);
            } else if (prevSelected.length < 2) {
                return [...prevSelected, userId];
            }
            return prevSelected;
        });
    };

    
    useEffect(() => {
        const { apt_user_country, apt_user_state, apt_user_dist, appt_user_village } = formData;
        const placeOfBirth = `${appt_user_village}, ${apt_user_dist}, ${apt_user_state}, ${apt_user_country}`;
        setFormData((prevState) => ({
            ...prevState,
            apt_user_pob: placeOfBirth // Concatenate the values
        }));
    }, [formData.apt_user_country, formData.apt_user_state, formData.apt_user_dist, formData.appt_user_village]);

    const handleSubmit = async (event) => {
        event.preventDefault(); // Prevent default form submission

        // Validate required fields
        // if (!formData.apt_date || !formData.apt_time || !formData.apt_user_name || !formData.apt_user_mobile || !formData.apt_user_email || !formData.apt_mode) {
        //     alert("Please fill in all required fields.");
        //     return;
        // }

        if (selectedAstrologers.length === 0) {
            alert("Please select at least one astrologer.");
            return;
        }

        setIsSubmitting(true); // Set submitting state to true

        const form = new FormData();
        form.append('apt_date', formData.apt_date);
        form.append('apt_time', formData.apt_time);
        form.append('apt_user_name', formData.apt_user_name);
        form.append('apt_user_mobile', formData.apt_user_mobile);
        form.append('apt_user_email', formData.apt_user_email);
        form.append('apt_user_address', formData.apt_user_address);
        form.append('apt_user_dob', formData.apt_user_dob);
        form.append('apt_user_tob', formData.apt_user_tob);
        form.append('apt_user_pob', formData.apt_user_pob);
        form.append('apt_mode', formData.apt_mode);


        // Ensure apt_services is an array
        formData.apt_services.forEach(service => {
            form.append('apt_services[]', service);
        });

        selectedAstrologers.forEach(astrologer => {
            form.append('asign_id[]', astrologer);
        });

        console.log('Form Data to be submitted:', form); // Log the form data

        try {
            const response = await fetch('https://alpastrology.online/alpapi/User_api/appointment', {
                method: 'POST',
                headers: {
                    'x-api-key': 'alp@123',
                },
                body: form,
            });

            const text = await response.text();
            console.log('Response text:', text);

            try {
                const responseData = JSON.parse(text);
                console.log('Form submission response:', responseData);

                if (responseData.status === "Success") {
                    setFormData({
                        apt_date: '',
                        apt_time: '',
                        apt_user_name: '',
                        apt_user_mobile: '',
                        apt_user_email: apt_email,
                        apt_user_address: '',
                        apt_mode: '',
                        apt_services: [],
                    });
                    setSelectedAstrologers([]);
                    alert('Appointment booked successfully!');
                    navigate('/MyAppointments')

                } else {
                    alert('Error submitting form. Please try again.');
                }
            } catch (error) {
                console.error('Error parsing JSON:', error);
                alert('Error parsing response. Please try again.');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            alert('Error submitting form. Please try again.');
        } finally {
            setIsSubmitting(false); // Reset submitting state
        }
    };

    return (


        <>
            <Header />
            <SideBar />
            <div className="app-wrapper">

                <div className="app-content pt-3 p-md-3 p-lg-4">
                    <div className="container-xl ">
                        <div className="wl_header">
                            <h3>Make an Appointments</h3>
                        </div>
                        <Form className="as_appointment_form pt-5 my-4" method="post" action="" onSubmit={handleSubmit}>
                            <Row >
                                <Col lg={6} md={6} sm={12} className="as_font14_subhead" data-aos="zoom-in-down" data-aos-duration="1100">
                                    <Row>
                                        <Col lg={6} md={6} sm={12}>
                                            <Form.Group className="mb-3 revealx as_font14">
                                                <Form.Label>Appointment Date</Form.Label>
                                                <Form.Control type="date" placeholder="Preferred Date" name="apt_date" className="form-control preferDate" onChange={(e) => { handleDateChange(e); handleInputChange(e); }} />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} md={6} sm={12}>
                                            <Form.Group className="mb-3 revealx as_font14">
                                                <Form.Label>Appointment Time</Form.Label>
                                                <Form.Control type="time" placeholder="Preferred apt_time" name="apt_time" className="form-control as_apt_timepicker" onChange={handleInputChange} />
                                                <span><img src="assets/images/svg/clock.svg" alt="" /></span>
                                            </Form.Group>
                                        </Col>

                                        <Col lg={6} md={6} sm={12}>
                                            <Form.Group className="mb-3 revealx as_font14">
                                                <Form.Control type="text" name="apt_user_name" placeholder="Name" className="form-control" onKeyUp={(e) => e.target.value = e.target.value.replace(/[^A-z]/g, '')} required onChange={handleInputChange} />
                                                <span><img src="assets/images/svg/user1.svg" alt="" /></span>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} md={6} sm={12}>
                                            <Form.Group className="mb-3 revealx as_font14">
                                                <Form.Control type="text" placeholder="Phone Number" name="apt_user_mobile" className="form-control" required onChange={handleInputChange} />
                                                <span><img src="assets/images/svg/phone1.svg" alt="" /></span>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} md={6} sm={12}>
                                            <Form.Group className=" revealx as_font14">
                                                <Form.Label>Date of Birth</Form.Label>
                                                <Form.Control type="date" placeholder="Preferred Date" name="apt_user_dob" className="form-control preferDate" onChange={(e) => { handleDateChange(e); handleInputChange(e); }} />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} md={6} sm={12}>
                                            <Form.Group className=" revealx as_font14">
                                                <Form.Label>Time of Birth</Form.Label>
                                                <Form.Control type="time" placeholder="Preferred apt_time" name="apt_user_tob" className="form-control as_apt_timepicker" onChange={handleInputChange} />
                                                <span><img src="assets/images/svg/clock.svg" alt="" /></span>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} md={6} sm={12}>
                                            <Form.Group className="mb-3 revealx as_font14">
                                                <Form.Control type="text" name="apt_user_address" placeholder="City/Country" className="form-control" onChange={handleInputChange} />
                                                <span><img src="assets/images/svg/pin.svg" alt="" /></span>
                                            </Form.Group>
                                        </Col>

                                        <Col lg={6} md={6} sm={12}>
                                            <Form.Group className="mb-3 revealx as_font14" style={{ color: 'black' }}>
                                                <Form.Select className="form-control mb-3" name="apt_mode" aria-label="Consultation Mode" onChange={handleInputChange}>
                                                    <option>Mode of Consultation</option>
                                                    <option value="Voice Call">Voice Call</option>
                                                    <option value="Video Call">Video Call</option>
                                                </Form.Select>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Form.Group>
                                        <Form.Label>Place of Birth Details</Form.Label>
                                    </Form.Group>
                                    <Row>
                                        <Col lg={6} md={6} sm={12}>
                                            <Form.Group className="revealx as_font14">
                                                <Form.Control
                                                    type="text"
                                                    name="apt_user_country"
                                                    placeholder="Country"
                                                    className="form-control"
                                                    onKeyUp={(e) => e.target.value = e.target.value.replace(/[^A-z]/g, '')}
                                                    value={formData.apt_user_country}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <span><img src="assets/images/svg/user1.svg" alt="" /></span>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} md={6} sm={12}>
                                            <Form.Group className="revealx as_font14">
                                                <Form.Control
                                                    type="text"
                                                    name="apt_user_state"
                                                    placeholder="State"
                                                    className="form-control"
                                                    value={formData.apt_user_state}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <span><img src="assets/images/svg/phone1.svg" alt="" /></span>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col lg={6} md={6} sm={12}>
                                            <Form.Group className="revealx as_font14">
                                                <Form.Control
                                                    type="text"
                                                    name="apt_user_dist"
                                                    placeholder="District"
                                                    className="form-control"
                                                    onKeyUp={(e) => e.target.value = e.target.value.replace(/[^A-z]/g, '')}
                                                    value={formData.apt_user_dist}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <span><img src="assets/images/svg/user1.svg" alt="" /></span>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6} md={6} sm={12}>
                                            <Form.Group className="revealx as_font14">
                                                <Form.Control
                                                    type="text"
                                                    name="appt_user_village"
                                                    placeholder="Town/Village"
                                                    className="form-control"
                                                    value={formData.appt_user_village}
                                                    onChange={handleInputChange}
                                                    required
                                                />
                                                <span><img src="assets/images/svg/phone1.svg" alt="" /></span>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Form.Group className="revealx as_font14" style={{ color: 'black' }}>
                                        <Form.Group className="revealx as_font14">
                                            <Form.Control
                                                type="text"
                                                placeholder="Place of Birth"
                                                name="apt_user_pob"
                                                className="form-control"
                                                value={formData.apt_user_pob} // Dynamically updated value
                                                readOnly
                                            />
                                            <span><img src="assets/images/svg/phone1.svg" alt="" /></span>
                                        </Form.Group>
                                    </Form.Group>
                                </Col>
                                <Row>

                                    <Col lg={6} md={6} sm={12}>
                                        <Form.Group className="mb-3">
                                            <div className="as_select_box as_font14_subhead">
                                                <h4 data-aos="zoom-out-left" className='ms-3'>Select Services</h4>
                                                <section className="ftco-section as_font14">
                                                    <div className="row justify-content-center">
                                                        <ul className="ks-cboxtags">
                                                            <Row>
                                                                <Col lg={6} md={6} sm={12}>
                                                                    <li className="revealx" data-aos="zoom-out-left" data-aos-duration="1000">
                                                                        <Form.Check type="checkbox" id="checkboxOne" name="apt_services[]" value="ALPAstrology" label="ALP Astrology" onChange={handleCheckboxChange} />
                                                                    </li>
                                                                </Col>
                                                                <Col lg={6} md={6} sm={12}>
                                                                    <li className="revealx" data-aos="zoom-out-left" data-aos-duration="1050">
                                                                        <Form.Check type="checkbox" id="checkboxSix" name="apt_services[]" value="ALPThirumanaPorutham" label="ALP Thirumana Porutham" onChange={handleCheckboxChange} />
                                                                    </li>
                                                                </Col>
                                                            </Row>
                                                            <Row className='mt-2'>
                                                                <Col lg={6} md={6} sm={12}>

                                                                    <li className="revealx" data-aos="zoom-out-left" data-aos-duration="1100">
                                                                        <Form.Check type="checkbox" id="checkboxThree" name="apt_services[]" value="ALPNumerology" label="ALP Numerology" onChange={handleCheckboxChange} />
                                                                    </li>
                                                                </Col>
                                                                <Col lg={6} md={6} sm={12}>
                                                                    <li className="revealx" data-aos="zoom-out-left" data-aos-duration="1150">
                                                                        <Form.Check type="checkbox" id="checkboxFour" name="apt_services[]" value="ALPGemology" label="ALP Gemology" onChange={handleCheckboxChange} />
                                                                    </li>
                                                                </Col>
                                                            </Row>
                                                            <Row className='mt-2'>
                                                                <Col lg={6} md={6} sm={12}>
                                                                    <li className="revealx" data-aos="zoom-out-left" data-aos-duration="1200">
                                                                        <Form.Check type="checkbox" id="checkboxFive" name="apt_services[]" value="ALPPrasannam" label="ALP Prasannam" onChange={handleCheckboxChange} />
                                                                    </li>
                                                                </Col>
                                                                <Col lg={6} md={6} sm={12}>
                                                                    <li className="revealx" data-aos="zoom-out-left" data-aos-duration="125 0">
                                                                        <Form.Check type="checkbox" id="checkboxTwo" name="apt_services[]" value="ALPVastu" label="ALP Vastu" onChange={handleCheckboxChange} />
                                                                    </li>
                                                                </Col>
                                                            </Row>
                                                        </ul>
                                                    </div>
                                                </section>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Col lg={12} md={12} sm={12} className="as_font14_subhead">
                                    <h4>Choose any 2 Astrologers</h4>
                                    <Row>
                                        {availableAstrologers.length > 0 ? (
                                            availableAstrologers.map((astrologer, index) => (
                                                <Col lg='3' md='4' key={index} xs='5' className='m-1'>
                                                    <div>
                                                        <img
                                                            src={astrologer.astrologers_image}
                                                            alt={astrologer.astrologers_name}
                                                            width={100} data-aos="fade-up"
                                                            data-aos-duration="3000"
                                                        />
                                                        <Form.Check
                                                            type="checkbox"
                                                            id={`astrologer_${astrologer.astrologers_id}`}
                                                            name="asign_id[]"
                                                            value={astrologer.user_id}
                                                            checked={selectedAstrologers.includes(astrologer.astrologers_id)}
                                                            onChange={() => handleAstrologerSelection(astrologer.astrologers_id)}
                                                            disabled={!selectedAstrologers.includes(astrologer.astrologers_id) && selectedAstrologers.length >= 2}
                                                            label={astrologer.astrologers_name}
                                                            required
                                                        />
                                                    </div>
                                                </Col>
                                            ))
                                        ) : (
                                            <p>No astrologers available.</p>
                                        )}
                                    </Row>
                                    <p><b>Note :</b></p>
                                    <ul>
                                        <li>Consultation will ONLY be given by reputed ALP astrologers assigned by Dr. S. Pothuvudaimoorthy.</li>
                                        <li>Dr. S. Pothuvudaimoorthy DOES NOT give any personalised consultation for any of these above mentioned appointments.</li>
                                    </ul>
                                </Col>
                                <Col lg={12} className='my-1'>
                                    <Form.Check type="checkbox" id="consentCheck1" name="s" value="" required label="I agree to all the above mentioned terms and conditions and hereby give my consent for taking ALP consultation services." />
                                </Col>
                                <Col lg={12} className='my-1'>
                                    <Form.Check type="checkbox" id="consentCheck2" name="s" value="" required label="Signature of the client." />
                                </Col>
                                <Col lg={12} className='my-1'>
                                    <Form.Check type="radio" id="acceptRadio" name="apt_req" value="Accept" required label="Accept" />
                                    <Form.Check type="radio" id="denyRadio" name="apt_req" value="Deny" required label="Deny" />
                                </Col>
                                <Col lg={12} className="text-center">
                                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                                        {isSubmitting ? 'Submitting...' : 'Submit'}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>

                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default MakeAppt