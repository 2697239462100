import React, { useState } from 'react'
import Appbar from './AppBar'
import Footer from './Footer'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import '../css/Books.css'
import book_1 from '../assets/Books/book_01.jpg'
import book_2 from '../assets/Books/book_02.jpg'
import book_3 from '../assets/Books/book_03.jpg'
import book_4 from '../assets/Books/book_04.jpg'
import book_5 from '../assets/Books/book_05.jpg'
import book_6 from '../assets/Books/book_06.jpg'
import book_7 from '../assets/Books/book_07.jpg'
import Ebook_1 from '../assets/Books/englishb1.jpg'
import Ebook_2 from '../assets/Books/englishb2.jpg'
import Ebook_3 from '../assets/Books/englishb3.jpg'
import Select from 'react-select';
import axios from 'axios'
const Books = () => {
    const[userName,setUserName]=useState()
    const[userEmail,setuserEmail]=useState()
    const[userMobile,setuserMobile]=useState()
    const[order,setOrder]=useState('Order')
    const[userAddress,setUserAddress] = useState()
    const [selectedBooks, setSelectedBooks] = useState([]);
    const bookOptions = [
        { value: 'ALP Volume 1', label: 'ALP Volume 1' },
        { value: 'ALP Volume 2', label: 'ALP Volume 2' },
        { value: 'ALP Volume 3', label: 'ALP Volume 3' },
        { value: 'ALP Volume 4', label: 'ALP Volume 4' },
        { value: 'ALP Volume 5', label: 'ALP Volume 5' },
        { value: 'ALP Volume 6', label: 'ALP Volume 6' },
        { value: 'ALP Thirumana Porutham', label: 'ALP Thirumana Porutham' },
        { value: 'ALP English Volume 1', label: 'ALP English Volume 1' },
        { value: 'ALP English Volume 2', label: 'ALP English Volume 2' },
        { value: 'ALP English Volume 3', label: 'ALP English Volume 3' },
    ];

    const handleSubmit = (e) => {
        e.preventDefault();
        const selectedBookValues = selectedBooks.map((book) => book.value); // Extract values
    
        const booksString = selectedBookValues.join(','); // Join values into a comma-separated string
    
        const formData = new FormData();
        formData.append('username', userName);
        formData.append('useremail', userEmail);
        formData.append('usermobile', userMobile);
        formData.append('useraddress', userAddress);
        formData.append('enquiry_books', booksString); // Send as a single string
        formData.append('order_type', order);
    
        axios.post('https://alpastrology.online/alpapi/User_api/order_books', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-api-key': 'alp@123',
            },
        })
        .then((response) => {
            if (response.data.status === 'Success') {
                alert('Ordered Successfully');
                setUserName('');
                setuserEmail('');
                setuserMobile('');
                setUserAddress('');
                setSelectedBooks([]);
                setOrder('');
            } else {
                alert('Failed to Order Books');
            }
        })
        .catch((error) => {
            console.error('Failed to order:', error);
        });
    };
    
    return (
        <>
            <Appbar />
            <div className='Courses_page'>
                <div className="overlay"></div>
                <div className="title" >
                    <h1 className="display-1 fw-bold consultation_title" data-aos="fade-down-right" data-aos-duration="1000">Books</h1>
                    <div className="nav-home">
                        <ul className="home_cons" data-aos="flip-left" data-aos-duration="1000">
                            <li className="mx-3"><a href="https://www.alpastrology.co.in/index" className="text-white">Home</a></li>
                            <li className="mx-3"><img src='../assets/star.png' alt="Star" /></li>
                            <li className="mx-3">Books</li>
                        </ul>
                    </div>
                </div>
            </div>
            <Container className='my-5 book_container'>
                <h2 className='text-center fw-bold'><span className='alp'>ALP</span> Tamil Books</h2>
                <Row>
                    <Col lg={3} md={3} sm={6} xs={6}>
                        <div class="card">
                            <div class="imgBx">
                                <img src={book_1} alt="Books_1" />
                            </div>
                            <div class="contentBx">
                                <h4>ALP Volume 1</h4>
                                <div class="color">
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={3} sm={6} xs={6}>
                        <div class="card">
                            <div class="imgBx">
                                <img src={book_2} alt="Books_1" />
                            </div>
                            <div class="contentBx">
                                <h4>ALP Volume 2</h4>
                                <div class="color">
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={3} sm={6} xs={6}>
                        <div class="card">
                            <div class="imgBx">
                                <img src={book_3} alt="Books_1" />
                            </div>
                            <div class="contentBx">
                                <h4>ALP Volume 3</h4>
                                <div class="color">
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={3} sm={6} xs={6}>
                        <div class="card">
                            <div class="imgBx">
                                <img src={book_4} alt="Books_1" />
                            </div>
                            <div class="contentBx">
                                <h4>ALP Volume 4</h4>
                                <div class="color">
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row><br />
                <Row>
                    <Col lg={3} md={3} sm={6} xs={6}>
                        <div class="card">
                            <div class="imgBx">
                                <img src={book_5} alt="Books_1" />
                            </div>
                            <div class="contentBx">
                                <h4>ALP Volume 5</h4>
                                <div class="color">
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={3} sm={6} xs={6}>
                        <div class="card">
                            <div class="imgBx">
                                <img src={book_6} alt="Books_1" />
                            </div>
                            <div class="contentBx">
                                <h4>ALP Volume 6</h4>
                                <div class="color">
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={3} sm={6} xs={6}>
                        <div class="card">
                            <div class="imgBx">
                                <img src={book_7} alt="Books_1" />
                            </div>
                            <div class="contentBx">
                                <h4>ALP Thirumana Porutham</h4>
                                <div class="color">
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <hr />
                <h2 className='text-center fw-bold'><span className='alp'>ALP</span> Enlish Books</h2>
                <Row>
                    <Col lg={3} md={3} sm={6} xs={6}>
                        <div class="card">
                            <div class="imgBx">
                                <img src={Ebook_1} alt="Books_1" />
                            </div>
                            <div class="contentBx">
                                <h4>ALP English Volume 1</h4>
                                <div class="color">
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={3} sm={6} xs={6}>
                        <div class="card">
                            <div class="imgBx">
                                <img src={Ebook_2} alt="Books_1" />
                            </div>
                            <div class="contentBx">
                                <h4>ALP English Volume 1</h4>
                                <div class="color">
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} md={3} sm={6} xs={6}>
                        <div class="card">
                            <div class="imgBx">
                                <img src={Ebook_3} alt="Books_1" />
                            </div>
                            <div class="contentBx">
                                <h4>ALP English Volume 1</h4>
                                <div class="color">
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-5 pt-5 justify-content-center">
                    <h3 className="text-center">Order Books</h3>
                    <br />
                    <hr />
                    <span className="text-warning fs-3 text-center">
                        For inquiries and order books, please contact us at:
                    </span>
                    <Col lg={8} md={10} sm={12} xs={12} className="mx-auto">
                        <Form onSubmit={handleSubmit} className="p-4">
                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="formName">
                                        <Form.Control
                                            type="text"
                                            placeholder="Name"
                                            required
                                            value={userName}
                                            onChange={(e) => setUserName(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="formEmail">
                                        <Form.Control
                                            type="email"
                                            placeholder="Email"
                                            value={userEmail}
                                            required
                                            pattern="[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$"
                                            onChange={(e) => setuserEmail(e.target.value)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col md={6}>
                                    <Form.Group controlId="formPhone">
                                        <Form.Control type="text" placeholder="Phone Number" value={userMobile} onChange={(e)=>setuserMobile(e.target.value)} required />
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group controlId="formOrderType">
                                        <Form.Select required value={order} onChange={(e)=>setOrder(e.target.value)}>
                                            <option value="Order">Order</option>
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col md={12}>
                                    <Form.Group controlId="formSelectBooks">
                                        <Form.Label>Select Books</Form.Label>
                                        <Select
                                            options={bookOptions}
                                            isMulti
                                            placeholder="Search..."
                                            onChange={(selected) => setSelectedBooks(selected)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row className="mb-3">
                                <Col md={12}>
                                    <Form.Group controlId="formAddress">
                                        <Form.Control as="textarea" placeholder="Address" value={userAddress} onChange={(e)=>setUserAddress(e.target.value)} rows={3} />
                                    </Form.Group>
                                </Col>
                            </Row>

                            <div className="text-center">
                                <Button variant="primary" type="submit">
                                    Order Now
                                </Button>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>

    )
}

export default Books