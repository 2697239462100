import React, { useState } from 'react';
import '../css/DetailsForm.css';

const DetailsForm = ({ triggerNextStep }) => {
  const [formData, setFormData] = useState({
    user_name: '',
    user_mob: '',
    user_dob: '',
    user_tob: '',
    user_pob: '',
    user_email: '',
    user_req: ''
  });
  
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.user_name) newErrors.user_name = 'Name is required';
    if (!formData.user_mob) newErrors.user_mob = 'Mobile number is required';
    if (!formData.user_dob) newErrors.user_dob = 'Date of birth is required';
    if (!formData.user_tob) newErrors.user_tob = 'Time of birth is required';
    if (!formData.user_pob) newErrors.user_pob = 'Place of birth is required';
    if (!formData.user_email) newErrors.user_email = 'Email is required';
    if (!formData.user_req) newErrors.user_req = 'Please select a request';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      console.log('Details Submitted:', formData);
      const form = new FormData();
      form.append('user_name', formData.user_name);
      form.append('user_mob', formData.user_mob);
      form.append('user_dob', formData.user_dob);
      form.append('user_tob', formData.user_tob);
      form.append('user_pob', formData.user_pob);
      form.append('user_email', formData.user_email);
      form.append('user_req_type', formData.user_req);

      try {
        const response = await fetch('https://alpastrology.online/alpapi/User_api/users_reg', {
          method: 'POST',
          headers: {
            'x-api-key': 'alp@123'
          },
          body: form
        });

        if (response.ok) {
          const data = await response.json();
          console.log('Response from server:', data);
          triggerNextStep();
        } else {
          console.error('Error submitting the details form:', response.statusText);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    }
  };

  return (
    <form className="details-form" onSubmit={handleSubmit}>
      <h2>Enter Your Details</h2>
      
      <div>
        <label>Name:</label>
        <input
          type="text"
          name="user_name"
          value={formData.user_name}
          onChange={handleChange}
        />
        {errors.user_name && <div className="error">{errors.user_name}</div>}
      </div>
      
      <div>
        <label>Mobile:</label>
        <input
          type="text"
          name="user_mob"
          value={formData.user_mob}
          onChange={handleChange}
        />
        {errors.user_mob && <div className="error">{errors.user_mob}</div>}
      </div>
      
      <div>
        <label>Date of Birth:</label>
        <input
          type="date"
          name="user_dob"
          value={formData.user_dob}
          onChange={handleChange}
        />
        {errors.user_dob && <div className="error">{errors.user_dob}</div>}
      </div>
      
      <div>
        <label>Time of Birth:</label>
        <input
          type="time"
          name="user_tob"
          value={formData.user_tob}
          onChange={handleChange}
        />
        {errors.user_tob && <div className="error">{errors.user_tob}</div>}
      </div>
      
      <div>
        <label>Place of Birth:</label>
        <input
          type="text"
          name="user_pob"
          value={formData.user_pob}
          onChange={handleChange}
        />
        {errors.user_pob && <div className="error">{errors.user_pob}</div>}
      </div>
      
      <div>
        <label>Email:</label>
        <input
          type="email"
          name="user_email"
          value={formData.user_email}
          onChange={handleChange}
        />
        {errors.user_email && <div className="error">{errors.user_email}</div>}
      </div>
      
      <div>
        <label>Do you want?</label>
        <select
          name="user_req"
          value={formData.user_req}
          onChange={handleChange}
        >
          <option value=''>Select</option>
          <option value='ALP Astrology'>ALP Astrology</option>
          <option value='Vastu Shastra'>Vastu Shastra</option>
          <option value='Numerology'>Numerology</option>
          <option value='ALP Prasanam'>ALP Prasanam</option>
          <option value='Gemology'>Gemology</option>
          <option value='Thirumana Porutham'>Thirumana Porutham</option>
        </select>
        {errors.user_req && <div className="error">{errors.user_req}</div>}
      </div>
      
      <button type="submit">Submit</button>
    </form>
  );
};

export default DetailsForm;
