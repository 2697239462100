import React, { useEffect, useState } from 'react';
import logo from '../assets/img/login_logo_mob.gif';
import '../assets/css/portal.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Login() {
    const [userEmail, setuserEmail] = useState('');
    const [userMobile, setuserMobile] = useState('');
    const navigate = useNavigate();  // Corrected to call useNavigate()

    useEffect(() => {
        const loggedIn = localStorage.getItem('loggedIn');
        if (loggedIn) {
            navigate('/Dashboard', { replace: true });
        }
    }, [navigate]);

    const handleLogin = (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append('user_email', userEmail);
        formData.append('user_mob', userMobile);

        axios.post(`https://alpastrology.online/alpapi/User_api/login_user`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'x-api-key': 'alp@123'
            }
        }).then((response) => {
            if (response.data.status === 'Success') {  // Check response.data.status
                console.log('Login Success');
                localStorage.setItem('loggedIn',true);
                localStorage.setItem('userId',JSON.parse(response.data.data.user_id));
                localStorage.setItem('userName', response.data.data.user_name);
                localStorage.setItem('userEmail', response.data.data.user_email);
                localStorage.setItem('userMobile', response.data.data.user_mob);
                navigate('/Dashboard', { replace: true });  // Fixed the typo
            } else {
                alert('Invalid Login');
            }
        }).catch((error) => {
            console.log('Login Error:', error);
        });
    };

    return (
        <div className="row g-0 app-auth-wrapper">
            <div className="col-12 col-md-7 col-lg-6 auth-main-col text-center p-5">
                <div className="d-flex flex-column align-content-end">
                    <div className="app-auth-body mx-auto">
                        <div className="app-auth-branding mb-4">
                            <a className="app-login-logo" href="index.html">
                                <img className="logo-icon me-2" src={logo} alt="logo" />
                            </a>
                            <h4 className="text-center" style={{ color: 'red' }}>Login</h4>
                        </div>
                        <h1 className="auth-heading text-center mb-5"> Login</h1>
                        <div className="auth-form-container text-start">
                            <form className="auth-form login-form" autoComplete='off' onSubmit={handleLogin}>
                                <div className="email mb-3">
                                    <label className="sr-only" htmlFor="signin-email">Email</label>
                                    <input
                                        id="signin-email"
                                        name="user_email"
                                        type="text"
                                        className="form-control signin-email log_input"
                                        placeholder="Email address"
                                        required
                                        value={userEmail}
                                        onChange={(e) => setuserEmail(e.target.value)}
                                    />
                                </div>
                                <div className="password mb-3">
                                    <label className="sr-only" htmlFor="signin-password">Mobile</label>
                                    <input
                                        id="signin-password"
                                        name="user_mobile"
                                        type="text"
                                        className="form-control signin-password log_input"
                                        placeholder="Mobile"
                                        required
                                        value={userMobile}
                                        onChange={(e) => setuserMobile(e.target.value)}
                                    />
                                    <div className="extra mt-3 row justify-content-between">
                                        <div className="col-6">
                                            <div className="form-check">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    value=""
                                                    id="RememberPassword"
                                                />
                                                <label className="form-check-label" htmlFor="RememberPassword">
                                                    Remember me
                                                </label>
                                            </div>
                                        </div>
                                        <div className="col-6"></div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <button type="submit" className="app-btn-primarys w-100 theme-btn mx-auto">
                                        Log In
                                    </button>
                                </div>
                            </form>

                            <div className="auth-option text-center pt-5">
                                No Account? Sign up <a className="text-link" href="signup.html">here</a>.
                            </div>
                        </div>
                    </div>

                    <footer className="app-auth-footer">
                        <div className="container text-center py-3">
                            <small className="copyright">
                                Designed with <span className="sr-only">love</span>
                                <i className="fas fa-heart" style={{ color: '#fb866a' }}></i> by{' '}
                                <a className="app-link" href="https://codriveit.com" target="_blank" rel="noopener noreferrer">
                                    Codriveit
                                </a> for developers
                            </small>
                        </div>
                    </footer>
                </div>
            </div>
            <div className="col-12 col-md-5 col-lg-6 h-100 auth-background-col">
                <div className="auth-background-holder"></div>
            </div>
        </div>
    );
}

export default Login;
