import React, { useState, useEffect } from 'react';
import { Row, Col, Form } from 'react-bootstrap';

const MyComponent = () => {
    // State to store input values for each field
    const [formData, setFormData] = useState({
        apt_user_country: '',
        apt_user_state: '',
        apt_user_dist: '',
        appt_user_village: '',
        apt_user_pob: ''
    });

    // Function to handle input change and update state
    const handleInputChange = (e) => {
        const { name, value } = e.target;   
        setFormData({
            ...formData,
            [name]: value // Update the field that was changed
        });
    };

    // Update Place of Birth dynamically when other fields change
    useEffect(() => {
        const { apt_user_country, apt_user_state, apt_user_dist, appt_user_village } = formData;
        const placeOfBirth = `${appt_user_village}, ${apt_user_dist}, ${apt_user_state}, ${apt_user_country}`;
        setFormData((prevState) => ({
            ...prevState,
            apt_user_pob: placeOfBirth // Concatenate the values
        }));
    }, [formData.apt_user_country, formData.apt_user_state, formData.apt_user_dist, formData.appt_user_village]);

    return (
        <Form>
            <Row>
                <Col lg={6} md={6} sm={12}>
                    <Form.Group className="revealx as_font14">
                        <Form.Control
                            type="text"
                            name="apt_user_country"
                            placeholder="Country"
                            className="form-control"
                            onKeyUp={(e) => e.target.value = e.target.value.replace(/[^A-z]/g, '')}
                            value={formData.apt_user_country}
                            onChange={handleInputChange}
                            required
                        />
                        <span><img src="assets/images/svg/user1.svg" alt="" /></span>
                    </Form.Group>
                </Col>
                <Col lg={6} md={6} sm={12}>
                    <Form.Group className="revealx as_font14">
                        <Form.Control
                            type="text"
                            name="apt_user_state"
                            placeholder="State"
                            className="form-control"
                            value={formData.apt_user_state}
                            onChange={handleInputChange}
                            required
                        />
                        <span><img src="assets/images/svg/phone1.svg" alt="" /></span>
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col lg={6} md={6} sm={12}>
                    <Form.Group className="revealx as_font14">
                        <Form.Control
                            type="text"
                            name="apt_user_dist"
                            placeholder="District"
                            className="form-control"
                            onKeyUp={(e) => e.target.value = e.target.value.replace(/[^A-z]/g, '')}
                            value={formData.apt_user_dist}
                            onChange={handleInputChange}
                            required
                        />
                        <span><img src="assets/images/svg/user1.svg" alt="" /></span>
                    </Form.Group>
                </Col>
                <Col lg={6} md={6} sm={12}>
                    <Form.Group className="revealx as_font14">
                        <Form.Control
                            type="text"
                            name="appt_user_village"
                            placeholder="Town/Village"
                            className="form-control"
                            value={formData.appt_user_village}
                            onChange={handleInputChange}
                            required
                        />
                        <span><img src="assets/images/svg/phone1.svg" alt="" /></span>
                    </Form.Group>
                </Col>
            </Row>

            <Form.Group className="revealx as_font14" style={{ color: 'black' }}>
                <Form.Group className="revealx as_font14">
                    <Form.Control
                        type="text"
                        placeholder="Place of Birth"
                        name="apt_user_pob"
                        className="form-control"
                        value={formData.apt_user_pob} // Dynamically updated value
                        readOnly
                    />
                    <span><img src="assets/images/svg/phone1.svg" alt="" /></span>
                </Form.Group>
            </Form.Group>
        </Form>
    );
};

export default MyComponent;
