import React from 'react'
import Appbar from './AppBar'
import MainBanner from './MainBanner'
import AppointmentForm from './AppointMent'
import Footer from './Footer'
import Chatbot from './Chatbot'

const HomePage = () => {
    return (
        <>
            <Appbar />
            <MainBanner />
            <AppointmentForm />   
            <Footer /> 
            <Chatbot />
        </>
    )
}

export default HomePage