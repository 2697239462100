import React, { useEffect, useState } from 'react'
import Header from './Common/Header'
import SideBar from './Common/SideBar'
import Footer from './Common/Footer'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { BiSolidMessageAltDetail } from "react-icons/bi";
import { SiGoogledocs } from "react-icons/si";
import { BiSolidMessageRoundedDetail } from "react-icons/bi";
import Table from 'react-bootstrap/Table'
import axios from 'axios';
function Dashboard() {
    const [messageCount, setMessageCount] = useState(0)
    const [AppintmentCount, setAppintmentCount] = useState(0)

    const userId = localStorage.getItem('userId');
    const userEmail = localStorage.getItem('userEmail');

    useEffect(()=>{
        const fetchMsgCount = ()=>{
            axios.get(`https://alpastrology.online/alpapi/User_api/user_msg_count?user_id=${userId}`,{
                headers:{
                    'Content-Type':'multipart/form-data',
                    'x-api-key':'alp@123'
                }
            }).then((response)=>{
                if(response.data.status === 'Success'){
                    setMessageCount(response.data.Count)
                }else{
                    setMessageCount(0)
                    
                }
            }).catch((error)=>{
                console.error(error);
                
            })
        }
        fetchMsgCount();
    },[userId])

useEffect(()=>{
    const fetchAppointmentCount = ()=>{
        axios.get(`https://alpastrology.online/alpapi/User_api/user_appointment_count?user_email=${userEmail}`,{
            headers:{
                'Content-Type':'multipart/form-data',
                'x-api-key':'alp@123'
            }
        }).then((response)=>{
            if(response.data.status === 'Success'){
                setAppintmentCount(response.data.Count)
            }else{
                setAppintmentCount(0)

            }
        }).catch((error)=>{
            console.error(error);
            
        })
    }
    fetchAppointmentCount()
},[userEmail])

    return (

        <>
            <Header />
            <SideBar />
            <div className="app-wrapper">

                <div className="app-content pt-3 p-md-3 p-lg-4">
                    <div className="container-xl ">
                        <Row >

                            <Col lg={4}>
                                <Card style={{ width: '18rem' }} className='card2'>
                                    <Card.Body>
                                        <Card.Title className='text-center card_icon2 display-2 '><BiSolidMessageAltDetail className='icn2' /></Card.Title>
                                        <div className="count text-center">
                                            {messageCount}
                                        </div>
                                        <Card.Text className='text-center fw-bold'>
                                            My Messages
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={4}>
                                <Card style={{ width: '18rem' }} className='card3'>
                                    <Card.Body>
                                        <Card.Title className='text-center card_icon3 display-2 '><SiGoogledocs className='icn3' />

                                        </Card.Title>
                                        <div className="count text-center">
                                            {AppintmentCount}
                                        </div>
                                        <Card.Text className='text-center fw-bold'>
                                            Appointments
                                        </Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>

                        </Row>
                        {/* <Row className='mt-5'>
                            <Col lg={6}>
                                <Card className='card4'>
                                    <Card.Text className='ms-3 mt-3 fw-bold'>
                                        <BiSolidMessageRoundedDetail className='icn4' />  New Messages

                                    </Card.Text>
                                    <Card.Body>
                                        <Card.Title className='text-center card_icon3 display-2 '>
                                        </Card.Title>
                                        <div className=" text-center">
                                            <Table striped bordered hover variant="light" responsive>
                                                <thead>
                                                    <tr>
                                                        <th>S.No</th>
                                                        <th>Message</th>
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>


                                                </tbody>
                                            </Table>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>

                        </Row> */}

                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Dashboard