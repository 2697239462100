import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Row from 'react-bootstrap/Row';
import { Link } from 'react-router-dom';

const Appbar = () => {
  return (
    <Container fluid>

    <Row className="header">
      <Navbar expand="lg" className="header_nav" sticky='top'>
        <Container>
          <Navbar.Brand href="#home">
            <img src="/assets/logo.png" alt="Logo" width="100px" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
            <Nav className="fw-bold">
              <Nav.Link ><Link to="/" className="text-white m-1">Home</Link></Nav.Link>
              <Nav.Link ><Link to="/Course" className="text-white m-1">Course</Link></Nav.Link>
              <Nav.Link ><Link to="/Books" className="text-white m-1">Books</Link></Nav.Link>
              <Nav.Link ><Link to="/loginscreen" className="text-white m-1">Login</Link></Nav.Link>
              <Nav.Link ><Link to="/Contact" className="text-white m-1">Contact</Link></Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Row>
    </Container>
  );
};

export default Appbar;
