import React from 'react';

function Footer() {
    return (
        <>
         
            <script src="assets/plugins/popper.min.js"></script>
            <script src="assets/plugins/bootstrap/js/bootstrap.min.js"></script>

            <script src="assets/plugins/chart.js/chart.min.js"></script>
            <script src="assets/js/index-charts.js"></script>

            <script src="assets/js/app.js"></script>
        </>
    );
}
export default Footer;