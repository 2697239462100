import React, { useState, useEffect } from 'react';
import Header from './Common/Header';
import SideBar from './Common/SideBar';
import Footer from './Common/Footer';
import { Col, Row } from 'react-bootstrap';

const MakeMessage = () => {
    const [formData, setFormData] = useState({
        user_name: '',
        user_mob: '',
        user_dob: '',
        user_tob: '',
        user_pob: '',
        user_email: '',
        user_for_id: '',
        user_req: ''
    });
    const [errors, setErrors] = useState({});

    useEffect(() => {
        // Retrieve mobile and email from localStorage
        const user_for_id = localStorage.getItem('userId') || '';
        const user_email = localStorage.getItem('userEmail') || '';
        const user_mob = localStorage.getItem('userMobile') || '';
        setFormData((prevFormData) => ({
            ...prevFormData,
            user_email,
            user_mob,
            user_for_id
        }));
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.user_name) newErrors.user_name = 'Name is required';
        if (!formData.user_dob) newErrors.user_dob = 'Date of birth is required';
        if (!formData.user_tob) newErrors.user_tob = 'Time of birth is required';
        if (!formData.user_pob) newErrors.user_pob = 'Place of birth is required';
        if (!formData.user_email) newErrors.user_email = 'Email is required';
        if (!formData.user_mob) newErrors.user_mob = 'Mobile number is required';
        if (!formData.user_req) newErrors.user_req = 'Request type is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            console.log('Details Submitted:', formData);
            const form = new FormData();
            form.append('user_for_id', formData.user_for_id);
            form.append('user_name', formData.user_name);
            form.append('user_mob', formData.user_mob);
            form.append('user_dob', formData.user_dob);
            form.append('user_tob', formData.user_tob);
            form.append('user_pob', formData.user_pob);
            form.append('user_email', formData.user_email);
            form.append('user_req', formData.user_req);

            try {
                const response = await fetch('https://alpastrology.online/alpapi/User_api/make_message_appt', {
                    method: 'POST',
                    headers: {
                        'x-api-key': 'alp@123'
                    },
                    body: form
                });

                if (response.ok) {
                    const data = await response.json();
                    console.log('Response from server:', data);
                    alert('Message sent successfully');
                    // Reset the form after successful submission, keeping some data from localStorage
                    setFormData({
                        user_name: '',
                        user_mob: localStorage.getItem('userMobile') || '',
                        user_dob: '',
                        user_tob: '',
                        user_pob: '',
                        user_email: localStorage.getItem('userEmail') || '',
                        user_for_id: localStorage.getItem('userId') || '',
                        user_req: ''
                    });
                } else {
                    console.error('Error submitting the details form.');
                    alert('Error submitting the form');
                }
            } catch (error) {
                console.error('Error:', error);
                alert('An error occurred while sending the message');
            }
        }
    };

    return (
        <>
            <Header />
            <SideBar />
            <div className="app-wrapper">
                <div className="app-content pt-3 p-md-3 p-lg-4">
                    <div className="container-xl ">
                        <form className="details-forms" onSubmit={handleSubmit}>
                            <h2>Make New Message</h2>
                            <Row>
                                <Col lg={6}>
                                    <div>
                                        <label>Name:</label>
                                        <input
                                            type="text"
                                            name="user_name"
                                            value={formData.user_name}
                                            onChange={handleChange}
                                        />
                                        {errors.user_name && <div className="error">{errors.user_name}</div>}
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div>
                                        <label>Date of Birth:</label>
                                        <input
                                            type="date"
                                            name="user_dob"
                                            value={formData.user_dob}
                                            onChange={handleChange}
                                        />
                                        {errors.user_dob && <div className="error">{errors.user_dob}</div>}
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <div>
                                        <label>Time of Birth:</label>
                                        <input
                                            type="time"
                                            name="user_tob"
                                            value={formData.user_tob}
                                            onChange={handleChange}
                                        />
                                        {errors.user_tob && <div className="error">{errors.user_tob}</div>}
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div>
                                        <label>Place of Birth:</label>
                                        <input
                                            type="text"
                                            name="user_pob"
                                            value={formData.user_pob}
                                            onChange={handleChange}
                                        />
                                        {errors.user_pob && <div className="error">{errors.user_pob}</div>}
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div>
                                        <label>Do you want?</label>
                                        <select
                                            name="user_req"
                                            value={formData.user_req}
                                            onChange={handleChange}
                                            className='form-control'
                                        >
                                            <option value=''>Select</option>
                                            <option value='ALP Astrology'>ALP Astrology</option>
                                            <option value='Vastu Shastra'>Vastu Shastra</option>
                                            <option value='Numerology'>Numerology</option>
                                            <option value='ALP Prasanam'>ALP Prasanam</option>
                                            <option value='Gemology'>Gemology</option>
                                            <option value='Thirumana Porutham'>Thirumana Porutham</option>
                                        </select>
                                        {errors.user_req && <div className="error">{errors.user_req}</div>}
                                    </div>
                                </Col>
                            </Row><br />
                            <button type="submit">Submit</button>
                        </form>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default MakeMessage;
