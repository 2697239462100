import React from 'react'
import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Chatbot from './Components/Chatbot'
import Login from './Components/Login';
import Dashboard from './Components/Dashboard';
import MyProfile from './Components/MyProfile';
import MyMessage from './Components/MyMessage';
import MyAppoinments from './Components/MyAppoinments';
import MakeAppt from './Components/MakeAppt';
import Classes from './Components/Classes';
import MakeMessage from './Components/MakeMessage';
import MyComponent from './Components/rough';
import Courses from './Components/WebCourses';
import HomePage from './Components/HomePage';
import Contact from './Components/Contact';
import Books from './Components/Books';

function App() {
    return (
        <>
            <Router>
                <Routes>
                    {/* <Route path='/' element={<Chatbot />}/> */}
                    <Route path='/' element={<HomePage />} />
                    <Route path='/LoginScreen' element={<Login />} />
                    <Route path='/Dashboard' element={<Dashboard />} />
                    <Route path='/MyProfile' element={<MyProfile />} />
                    <Route path='/Mymessage' element={<MyMessage />} />
                    <Route path='/MyAppointments' element={<MyAppoinments />} />
                    <Route path='/MakeAppointment' element={<MakeAppt />} />
                    <Route path='/Classes' element={<Classes />} />
                    <Route path='/MakeMessage' element={<MakeMessage />} />
                    <Route path='/Course' element={<Courses />} />
                    <Route path='/test' element={<MyComponent />} />
                    <Route path='/contact' element={<Contact />} />
                    <Route path='/Books' element={<Books />} />
                </Routes>
            </Router>
        </>
    )
}
export default App;