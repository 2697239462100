import React from 'react';
// import bg_video from '../assets/video/mainbg_video.mp4';
// import star from '../assets/star.png';
import { Col, Container, Row } from 'react-bootstrap';


const MainBanner = () => {
  return (
    <div className="body-data">
      {/* <Appbar /> */}
      <Row>
        <Container fluid >
          <Row>
            <Col lg={12} className="setmainBanner">
              <div className="overlay"></div>

              <div className="title" >
                <h1 className="display-1 fw-bold consultation_title" data-aos="fade-down-right" data-aos-duration="1000">Consultation</h1>
                <div className="nav-home">
                  <ul className="home_cons" data-aos="flip-left" data-aos-duration="1000">
                    <li className="mx-3"><a href="https://www.alpastrology.co.in/index" className="text-white">Home</a></li>
                    <li className="mx-3"><img src='../assets/star.png' alt="Star" /></li>
                    <li className="mx-3">Consultation</li>
                  </ul>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Row>
      <Container fluid className="second-banner">
        <Container>
          <div className="terms_consultation">
            <Row>
              <p className="display-5 my-3 py-3 fw-bold mt-2 text-center" data-aos="zoom-in-down" >Terms & Conditions</p>
              <Col>
                <p className="fw-bold" data-aos="zoom-out">Please read the following rules and regulations carefully before booking your appointment and provide your consent by accepting the document below.</p>
                <div className="terms-details">
                  <ol className="terms-details-ol">
                    <li className="terms-details-li" data-aos="zoom-in-left" data-aos-duration="1250" >Consultation charges will be based on the services selected by the client.</li>
                    <li className="terms-details-li" data-aos="zoom-in-left" data-aos-duration="1250" >Appointment confirmation will be sent from the ALP office to your respective WhatsApp phone number.</li>
                    <li className="terms-details-li" data-aos="zoom-in-left" data-aos-duration="1250" >Priority based Consultation will be provided for clients based on the urgency of the situation, provided priority appointment slots are available at our end.</li>
                    <li className="terms-details-li" data-aos="zoom-in-left" data-aos-duration="1250" >Consultation will be provided by reputed ALP Astrologers.</li>
                    <li className="terms-details-li" data-aos="zoom-in-left" data-aos-duration="1250" >Questions pertaining to longevity and life span is strictly not entertained.</li>
                    <li className="terms-details-li" data-aos="zoom-in-left" data-aos-duration="1250" >Questions pertaining to determination of sex of baby during pregnancy is strictly not entertained.</li>
                    <li className="terms-details-li" data-aos="zoom-in-left" data-aos-duration="1250" >All predictions are completely based on the birth details and information provided by the client.</li>
                    <li className="terms-details-li" data-aos="zoom-in-left" data-aos-duration="1250" >We do not guarantee accuracy of predictions, as it may vary, if the information provided by the client is incorrect or inadequate.</li>
                  </ol>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </Container>
    </div>
  );
};

export default MainBanner;
