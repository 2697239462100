import React from 'react'
import { FaRegBell } from "react-icons/fa";

function Header(){
    const userName = localStorage.getItem('userName');
  return (
    <div className="app-header-inner">
    <div className="container-fluid py-2">
        <div className="app-header-content">
            <div className="row justify-content-between align-items-center">

             

                <div className="search-mobile-trigger d-sm-none col">
                    <i className="search-mobile-trigger-icon fa-solid fa-magnifying-glass"></i>
                </div>

                <div className="app-arsech-box col">
                   <h4 className='user-name ms-3 text-center'>{userName}</h4>
                </div>

                <div className="app-utilities col-auto">
                    <div className="app-utility-item app-notifications-dropdown dropdown">
                    
                            <FaRegBell />

                            <span className="icon-badge">0</span>
                       

                        <div className="dropdown-menu p-0" aria-labelledby="notifications-dropdown-toggle">
                            <div className="dropdown-menu-header p-3">
                                <h5 className="dropdown-menu-title mb-0">Notifications</h5>
                            </div>
                            <div className="dropdown-menu-content">
                                {/* Notification items go here */}
                            </div>
                            <div className="dropdown-menu-footer p-2 text-center">
                                <a href="notifications.html">View all</a>
                            </div>
                        </div>
                    </div>



                    <div className="app-utility-item app-user-dropdown dropdown">
                            {/* <img src={adminImage} alt="user profile" /> */}

                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
  )
}

export default Header