import React, { useState, useEffect } from 'react';
import ChatBot from 'react-simple-chatbot';
import { ThemeProvider } from 'styled-components';
import user from '../assets/img/user.png';
import chatbot from '../assets/img/chatbot.png';
import DetailsForm from './DetailsForm';
// import AppointMent from './AppointMent';
// import MainBanner from './MainBanner';
// import Footer from './Footer';
// import ScrollToTop from "react-scroll-to-top";
// import { AiOutlineArrowUp } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
// import Appbar from './AppBar';

const theme = {
    background: '#f0f8ff',
    fontFamily: 'Helvetica Neue',
    headerBgColor: '#4682b4',
    headerFontColor: 'white',
    headerFontSize: '15px',
    botBubbleColor: '#b0e0e6',
    botFontColor: 'black',
    userBubbleColor: '#6495ed',
    userFontColor: 'white',

};

const FloatingNotification = ({ message, onClose }) => {
    return (
        <div style={bubbleStyles.notification} onClick={onClose}>
            <div style={bubbleStyles.header}>
                <img src={chatbot} alt="Chatbot Avatar" style={bubbleStyles.avatar} />
                <h4 style={bubbleStyles.title}>ALP Astrology</h4>
            </div>
            <p style={bubbleStyles.message}>{message}</p>
            <button onClick={onClose} style={bubbleStyles.closeButton}>X</button>
        </div>
    );
};





const CustomOptionComponent = (props) => {
    const handleOptionClick = (option) => {
        props.triggerNextStep({ trigger: option.trigger });
    };

    return (
        <ul className="options-list">
            {options.map((option) => (
                <li key={option.value}>
                    <button onClick={() => handleOptionClick(option)}>
                        {option.label}
                    </button>
                </li>
            ))}
        </ul>
    );
};



const bubbleStyles = {
    notification: {
        position: 'fixed',
        bottom: '50px',
        right: '100px',
        backgroundColor: 'white',
        color: '#000',
        padding: '15px',
        borderRadius: '20px 20px 0px 20px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        maxWidth: '250px',
        zIndex: 1000,
        fontFamily: 'Arial, sans-serif',
        border: '2px solid #4682b4',
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '10px',
    },
    avatar: {
        width: '40px',
        height: '40px',
        marginRight: '10px',
    },
    title: {
        margin: 0,
        fontSize: '16px',
        fontWeight: 'bold',
        color: '#4682b4',
    },
    message: {
        fontSize: '14px',
        margin: '10px 0',
    },
    closeButton: {
        background: 'none',
        border: 'none',
        color: '#4682b4',
        fontSize: '16px',
        cursor: 'pointer',
        position: 'absolute',
        top: '5px',
        right: '10px',
        fontWeight: 'bolder',
    },
};

const options = [
    { value: 1, label: 'ALP Astrology', trigger: '6' },
    { value: 2, label: 'Vastu Shastra', trigger: '7' },
    { value: 3, label: 'Numerology', trigger: '8' },
    { value: 4, label: 'ALP Prasanam', trigger: '9' },
    { value: 5, label: 'Gemology', trigger: '10' },
    { value: 6, label: 'Thirumana Porutham', trigger: '11' },
];

function Chatbot() {
    const navigate = useNavigate();
    const [isChatbotOpen, setIsChatbotOpen] = useState(false);
    const [showNotification, setShowNotification] = useState(false);
    const [notificationClosed, setNotificationClosed] = useState(false);


    useEffect(() => {
        const reminderMessage = setInterval(() => {
            if (!isChatbotOpen && !notificationClosed) {
                setShowNotification(true);
            }
        }, 10000);

        return () => clearInterval(reminderMessage);
    }, [isChatbotOpen, notificationClosed]);

    const handleNotificationClose = () => {
        setShowNotification(false);
        setNotificationClosed(true);
        setIsChatbotOpen(true); // Open chatbot when notification closes
    };

    const toggleChatbot = () => {
        setIsChatbotOpen(prevState => !prevState);
        setShowNotification(false); // Hide the notification when chatbot is toggled
    };

    // Define handleChatbotEnd to close the chatbot
    const handleChatbotEnd = () => {
        setIsChatbotOpen(false); // Set the chatbot open state to false to close it
    };

    const steps = [
        {
            id: '1',
            message: 'Hi, How may I assist you?',
            trigger: '2',
        },
        {
            id: '2',
            message: 'Do you want Consultation or Course ?',
            trigger: 'crc',
        },  
        {
            id: 'crc',
            options: [
                {
                    value: 1,
                    label: 'Course',
                    trigger: () => {
                        navigate('/course'); // Navigate to login page
                        return 'course';
                    },
                },
                { value: 2, label: 'Consultation', trigger: '4' },
            ],
        },
        {
            id: '4',
            message: 'What are you looking for?',
            trigger: 'custom-options',
        },
        {
            id: 'custom-options',
            component: <CustomOptionComponent />,
            asMessage: true,
            waitAction: true,
        },
        {
            id: '6',
            message: 'You selected ALP Astrology.',
            trigger: 'acc',
        },
        {
            id: '7',
            message: 'You selected Vastu Shastra.',
            trigger: 'acc',
        },
        {
            id: '8',
            message: 'You selected Numerology.',
            trigger: 'acc',
        },
        {
            id: '9',
            message: 'You selected ALP Prasanam.',
            trigger: 'acc',
        },
        {
            id: '10',
            message: 'You selected Gemology.',
            trigger: 'acc',
        },
        {
            id: '11',
            message: 'You selected Thirumana Porutham.',
            trigger: 'acc',
        },
        {
            id: 'acc',
            message: 'Have account?',
            trigger: 'yn',
        },
        {
            id: 'yn',
            options: [
                {
                    value: 1,
                    label: 'Yes',
                    trigger: () => {
                        navigate('/LoginScreen'); // Navigate to login page
                        return 'LoginScreen';
                    },
                },
                { value: 2, label: 'No', trigger: '12' },
            ],
        },
        {
            id: 'login',
            component: <div>Redirecting to Login...</div>,
            end: true,
        },
        {
            id: '12',
            message: 'Want to consult our astrologers?',
            trigger: '13',
        },
        {
            id: '13',
            options: [
                { value: 1, label: 'Yes', trigger: '14' },
                { value: 2, label: 'No', trigger: 'custom-options' },
            ],
        },
        {
            id: '14',
            message: 'Please fill out the required details:',
            trigger: '15',
        },
        {
            id: '15',
            message: 'By filling the details you will be registered as well, Use email and mob.no to login',
            trigger: 'details',
        },
        {
            id: 'details',
            component: <DetailsForm />,
            asMessage: true,
            waitAction: true,
            trigger: '16',
        },
        {
            id: '16',
            message: 'ALP Chart will be shared shortly.',
            trigger: '17',
        },
        {
            id: '17',
            component: (
                <div>
                    <img src="/images/download.jpg" alt="alpchart" />
                </div>
            ),
            trigger: '18',
        },
        {
            id: '18',
            message: 'For further consultation,sign-up & book an appointment.',
            trigger: '19',
        },
        {
            id: '19',
            options: [
                {
                    value: 1,
                    label: 'Yes',
                    trigger: () => {
                        navigate('/signup');
                        return 'signup';
                    },
                },
                { value: 2, label: 'No', trigger: 'custom-options' },
            ],
        },
        {
            id: 'signup',
            component: <div>Redirecting to Signup...</div>,
            end: true,
        },
        // Other chatbot steps...
    ];


    return (
        <>


            {/* Floating Notification */}
            {!isChatbotOpen && showNotification && (
                <FloatingNotification
                    onClose={handleNotificationClose}
                    message="Hi there! 👋 Welcome to ALP Astrology. How can I assist you with your astrological queries today? Feel free to ask anything, and I'll guide you through the insights you need."
                />
            )}

            <div className='chatbot_animation'></div>

            {/* ChatBot Component */}
            <ThemeProvider theme={theme}>
                <ChatBot
                    steps={steps}
                    floating={true}
                    botDelay={3000}
                    headerTitle="ALP Chatbot"
                    botAvatar={chatbot}
                    userAvatar={user}
                    opened={isChatbotOpen}
                    handleEnd={handleChatbotEnd} // Close chatbot when finished
                    toggleFloating={toggleChatbot} // Toggle open/close chatbot
                    style={{ 
                        width: '450px', // Increase the width of the chatbot container
                        maxWidth: '100%' 
                    }} 
                />
                <div className='ovr_lap'>
                    <p className='ovr_lap_text'><svg class="curved-text" viewBox="0 0 120 120">
                        <path id="curve" fill="transparent" d="M 35,120 A 25,22 0 0,1 80,30" />
                        <text width="500">
                            <textPath href="#curve" startOffset="60%" text-anchor="middle">
                                👋 We Are Here!
                            </textPath>
                        </text>
                    </svg></p>
                </div>
            </ThemeProvider>
            {/* Scroll to Top Button */}
            
        </>
    );
}

export default Chatbot;
