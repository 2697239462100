import React, { useState } from 'react'
import Appbar from './AppBar'
import Footer from './Footer'
import Chatbot from './Chatbot'
import { Container,Form } from 'react-bootstrap'
import { FaGlobeAmericas } from "react-icons/fa";
import '../css/contact.css'
import { MdAttachEmail } from "react-icons/md";
import { FaMobileScreenButton } from "react-icons/fa6";
import { HiBuildingOffice2 } from "react-icons/hi2";
import axios from 'axios'


const Contact = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')

const handleContact = (e) =>{
    e.preventDefault()

    const formData = new FormData();
    formData.append('name',name);
    formData.append('email',email);
    formData.append('subject',subject);
    formData.append('message',message);
axios.post(`https://alpastrology.online/alpapi/User_api/sendEmail`,formData,{
    headers:{
        'Content-Type':'multipart/form-data',
        'x-api-key':'alp@123'
    }
}).then((response)=>{
    if(response.data.status === 'success'){
        alert('Send Email Success')
    }else{
        alert('send email failed')
    }
}).catch((error)=>{
    console.error('email send error :',error)
})


    

}
    return (
        <>
            <Appbar />
            <div className='Courses_page'>
                <div className="overlay"></div>
                <div className="title" >
                    <h1 className="display-1 fw-bold consultation_title" data-aos="fade-down-right" data-aos-duration="1000">Contact</h1>
                    <div className="nav-home">
                        <ul className="home_cons" data-aos="flip-left" data-aos-duration="1000">
                            <li className="mx-3"><a href="https://www.alpastrology.co.in/index" className="text-white">Home</a></li>
                            <li className="mx-3"><img src='../assets/star.png' alt="Star" /></li>
                            <li className="mx-3">Contact</li>
                        </ul>
                    </div>
                </div>
            </div>
            <Container className='my-4'>
                <h2 className='text-center fw-bold'>For inquiries about <span className='text-danger'>ALP</span> Astrology, Consultations, Courses, Softwares and Books please contact us at:</h2>
                <section className="mt-3">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12">
                                <div className="wrapper">
                                    <div className="row mb-5">

                                        <div className="col-md-3">
                                            <div className="dbox w-100 text-center">
                                                <div className="icon d-flex align-items-center justify-content-center">
                                                    <span className="fa fa-map-marker"><HiBuildingOffice2 /></span>
                                                </div>
                                                <div className="text">
                                                    <p><span>Address:</span> <br /> S.Pothuvudaimoorthy, PhD. Astrologer, 337 - Dr Radhakrishnan Salai, Ponnavarayan Kottai, Pattukkottai tk, Thanjavur dt. Pin Code 614602, Tamil Nadu, India.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="dbox w-100 text-center">
                                                <div className="icon d-flex align-items-center justify-content-center">
                                                    <span className="fa fa-phone"><FaMobileScreenButton /></span>
                                                </div>
                                                <div className="text">
                                                    <p><span>Phone:</span> <a href="tel://+919786556156">+91 9786556156</a></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="dbox w-100 text-center">
                                                <div className="icon d-flex align-items-center justify-content-center">
                                                    <span className="fa fa-paper-plane"><MdAttachEmail /></span>
                                                </div>
                                                <div className="text">
                                                    <p><span>Email:</span> <a
                                                        href="mailto:alpastrologyoffice@gmail.com"><span
                                                            className="__cf_email__"
                                                            data-cfemail="036a6d656c437a6c7671706a77662d606c6e">alpastrologyoffice@gmail.com</span></a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="dbox w-100 text-center">
                                                <div className="icon d-flex align-items-center justify-content-center">
                                               <span> <FaGlobeAmericas /></span>
                                                </div>
                                                <div className="text">
                                                    <p><span>Website</span> <a href="https://alpastrology.online">alpastrology.com</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row no-gutters mb-4">
                                        <div className="col-md-7">
                                            <div className="contact-wrap w-100 p-md-5 p-4">

                                                <Form onSubmit={handleContact}>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="label" for="name">Full Name</label>
                                                                <input type="text" className="form-control" name="name" value={name} onChange={(e)=>setName(e.target.value)} id="name"
                                                                    placeholder="Name" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <label className="label" for="email">Email Address</label>
                                                                <input type="email" className="form-control" name="email" value={email} onChange={(e)=>setEmail(e.target.value)} id="email"
                                                                    placeholder="Email" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label className="label" for="subject">Subject</label>
                                                                <input type="text" className="form-control" name="subject" value={subject} onChange={(e)=>setSubject(e.target.value)} id="subject"
                                                                    placeholder="Subject" />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <label className="label" for="#">Message</label>
                                                                <textarea name="message" className="form-control" id="message" value={message} onChange={(e)=>setMessage(e.target.value)} cols="30"
                                                                    rows="4" placeholder="Message"></textarea>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-12">
                                                            <div className="form-group">
                                                                <button type="submit" name="con_send"
                                                                    className="btn btn-primary rounded w-md mt-3">Send</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                        <div className="col-md-5 d-flex align-items-stretch contact_sideImg">
                                            <div className="info-wrap w-100 p-5 img" >
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </Container>
            <Footer />
            <Chatbot />
        </>
    )
}

export default Contact